:root {
  --primary-color: #13482f;
  --secondary-color: #e7edeb;
  --accent-color: #ea7c3c;
  --text-color: #000000;
  --background-color: #f6f6f6;
  --check-color: #4674c2;
  --risk-color: "#fce59e";
  --late-color: "#fd7777";
  --optimal-color: "#c4dfb4";
  --marker: "#245c42";
}
